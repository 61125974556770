import * as React from 'react';

import { ImageLoadingBehaviorOptions, MediaLabel } from 'wix-ui-tpa/cssVars';
import VideoFill from 'wix-ui-icons-common/on-stage/VideoFill';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ImageResizeMode } from '@wix/search-settings-client';

import { CroppedThumbnail } from './CroppedThumbnail';
import { FittedThumbnail } from './FittedThumbnail';
import { IThumbnailProps } from '../Thumbnail';
import { classes } from './ResizedThumbnail.st.css';
import { IDocumentImage, IWixImage } from '@wix/client-search-sdk';

export type IResizedThumbnailProps = Pick<
  IThumbnailProps,
  | 'height'
  | 'image'
  | 'maxResponsiveImageHeight'
  | 'maxResponsiveImageWidth'
  | 'width'
  | 'focalPoint'
> & {
  alt?: string;
  aspectRatio?: number;
  fluid: boolean;
  height: number;
  loadingBehavior: ImageLoadingBehaviorOptions;
  src: string;
  resizeMode: ImageResizeMode;
};

export const ResizedThumbnail: React.FC<IResizedThumbnailProps> = ({
  image,
  maxResponsiveImageWidth,
  maxResponsiveImageHeight,
  height,
  width,
  resizeMode,
  ...rest
}) => {
  const { isEditorX } = useEnvironment();

  const commonProps = {
    ...rest,
    height: isEditorX ? maxResponsiveImageHeight : height,
    width: isEditorX ? maxResponsiveImageWidth : width,
  };

  const showVideoIndicator = isVideoThumbnail(image);

  return (
    <div
      style={{
        height: isEditorX ? '100%' : height,
        width: isEditorX ? '100%' : width,
      }}
      className={classes.container}
    >
      {resizeMode === ImageResizeMode.Fit ? (
        <FittedThumbnail {...commonProps} image={image} />
      ) : (
        <CroppedThumbnail {...commonProps} />
      )}
      {showVideoIndicator ? (
        <div className={classes.videoIndicator}>
          <MediaLabel icon={<VideoFill />} />
        </div>
      ) : null}
    </div>
  );
};

const isVideoThumbnail = (
  image: NonNullable<IDocumentImage>,
): image is IWixImage =>
  'isVideoThumbnail' in image && Boolean(image.isVideoThumbnail);
